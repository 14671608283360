.pages-container {
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: #f8f9fa;
    width: 100%;
  }
  
  .header {
    background-color: #007bff;
    color: white;
    padding: 20px;
  }
  
  h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  
  h2 {
    color: #007bff;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .call-to-action {
    margin-top: 20px;
  }
  
  button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  .footer {
    margin-top: 40px;
    font-size: 0.9rem;
  }
  